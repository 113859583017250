/*=============================================
=            CTA Icon List            =
=============================================*/

.cta-icon-list {
    padding: $space--xxl;
	background-color: $bg--primary;
    color: $white;

    @include media-breakpoint-down(md) {
        padding: $space--xxl $space--md;
    }

    &__title {
        padding-bottom: $space--lg;

        @include DesktopH2;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
            
            @include DesktopH3;
        }
    }

    &__item {
        padding: 0 $space--md 0 0;

        @include media-breakpoint-down(md) {
            margin-top: $space--lg;
        }

        &--icon {
            img {
                width: auto;
                height: 48px;
                margin-bottom: $space--xs;
            }
        }

        &--title {
            font-size: $font-size--md;
            font-weight: $font-weight--bold;
            text-transform: uppercase;
            margin-bottom: $space--xs;
        }

        &--description {
            font-size: $font-size--xs;
            line-height: $line-height--lg;
        }
    }

    &__three {
        .cta-icon-list__item--title {
            @include DesktopH3;
        }

        .cta-icon-list__title {
            text-transform: none;
            font-weight: 300;
            max-width: 650px;

            @include BodyCopyLg;
        }
    }
}

/*=====  End of CTA Icon List  ======*/
