/*=============================================
=            Blog List Item            =
=============================================*/

.blog-list {
    position: relative;
    min-height: 560px;
    overflow: hidden;
    max-width: 1150px;
    margin: 0 auto;

	&__content {
        padding: $space--xxxxl $space--xxxxl $space--xxxxl 0;

        @include media-breakpoint-down(md) {
            padding: $space--md;
        }

        &-title {
            color: $text--primary;
            @include DesktopH3;
        }

        &-description {
            @include media-breakpoint-up(md) {
                padding-bottom: $space--xs;
            }

            @include BodyCopySm;
        }

        &-info {
            display: flex;
            padding: $space--xs 0;
            @include BodyCopyXs;
        }

        &-tag {
            color: $bg--primary;
        }

        &-date {
            color: $text--grey--light;
            text-transform: uppercase;
        }

        &-tag,
        &-date,
        &-location {
            margin-right: $space--xxxs;
        }
    }

    &__image {
        padding: $space--xxxxl $space--mega;

        &:hover {
            .blog-list__image-overlay {
                visibility: visible;
                opacity: 1;
            }

            .blog-list__image-overlay__text {
                visibility: visible;
                opacity: 1;
            }
        }

        @include media-breakpoint-down(md) {
            padding: $space--md $space--md 0 $space--md;
        }

        &-wrapper {
            position: relative;
        }

        &-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($bg--black, .44);
            color: #fff;
            visibility: hidden;
            opacity: 0;
            @include transition;

            &__text {
                position: absolute;
                bottom: 0;
                padding: $space--md;
                color: #fff;
                visibility: hidden;
                opacity: 0;
                @include transition;
            }
        }
    }

    &__hidden {
        display: none;
    }

    .rte {
        padding: 0;
    }

    .rte p {
        color: $text--grey;
    }
}

/*=====  End of Blog List Item  ======*/