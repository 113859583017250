/*=============================================
=            RTE            =
=============================================*/

.rte {
    padding: $space--lg 0;
    color: $text--grey;

    @include BodyCopySm;

    @include media-breakpoint-down(md) {
        padding: $space--xs;
    }

    &--grey {
        background-color: $bg--white--alt;
    }

    &--blue {
        background-color: $bg--primary;
        color: $text--light;

        .rte,
        .rte p {
            color: $text--light;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    ol,
    ul,
    p {
        display: block;
        margin-bottom: $space--xs;
    }

    h1 {
        @include DesktopH1;
    }

    h2 {
        @include DesktopH2;
    }

    h3 {
        @include DesktopH3;
    }

    h4 {
        @include DesktopH4;
    }

    h5 {
        @include DesktopH5;
    }

    p {
        color: $text--dark;
    }

    ul,
    ol,
    li {
        margin-left: $space--xxs;
    }

    ul,
    ol {
        margin-top: $space--xs;
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    strong {
        font-weight: $font-weight--bold;
    }

    a {
        @extend .button--primary;
        @extend .button--text-link;
    }

    .fr-view img.fr-dii.fr-fil {
        margin-right: 50px;

        @include media-breakpoint-down(md) {
            width: auto !important;
            margin-right: 0;
        }
    }

    &.fr-view img.fr-dii.fr-fil {
        margin-right: 20px;

        @include media-breakpoint-down(sm) {
            width: auto !important;
            margin-right: 0;
        }
    }

    .fr-view {
        word-wrap: normal;
        @include media-breakpoint-down(sm) {
            word-wrap: normal;
            display: inline-grid;
        }
    }

    &.fr-view {
        word-wrap: normal;
        @include media-breakpoint-down(md) {
            word-wrap: normal;
            display: inline-grid;
        }
    }
}

.text--center {
    text-align: center;
}

.ktc-widget-body-wrapper {
    .rte--blue {
        .rte,
        .rte p {
            color: $text--grey;
        }
    }
}

/*=====  End of RTE  ======*/
