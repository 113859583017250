.row {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );

    &--gap {
        column-gap: $space--md;
    }

    &--row-gap {
        row-gap: $space--md;
    }

    &--3 {
        grid-template-columns: repeat( 3, minmax(250px, 1fr) );
    }

    &--4 {
        grid-template-columns: repeat( 4, minmax(250px, 1fr) );
    }

    @include media-breakpoint-down(lg) {
        &-lg {
            &--2 {
                grid-template-columns: repeat( 2, minmax(250px, 1fr) );
            }
        }

        &-lg {
            &--1 {
                grid-template-columns: repeat( auto-fit, minmax(100%, 1fr) );
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-md {
            &--1 {
                grid-template-columns: repeat( auto-fit, minmax(100%, 1fr) );
            }
        }

        &-md {
            &--2 {
                grid-template-columns: repeat( 2, minmax(50%, 1fr) );
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-md {
            &--1 {
                grid-template-columns: repeat( auto-fit, minmax(100%, 1fr) );
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &-sm {
            &--2 {
                grid-template-columns: repeat( auto-fit, minmax(50%, 1fr) );
            }
        }

        &-sm {
            &--1 {
                grid-template-columns: repeat( auto-fit, minmax(100%, 1fr) );
            }
        }
    }

    @include media-breakpoint-down(xs) {
        &-xs {
            &--1 {
                grid-template-columns: repeat( auto-fit, minmax(100%, 1fr) );
            }
        }
    }
}

.justify-content-between {
    justify-content: space-between;
}
