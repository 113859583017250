/*=============================================
=            Table            =
=============================================*/

.table {
    padding: $space--xxxxl;

	&__title {
        padding-bottom: $space--lg;
        @include DesktopH3;
    }

    &__data {
        font-weight: $font-weight--bold;
    }

    &__container {
        display: table;
        width: 100%;
        border-collapse: collapse;
    }

    &__row {
        border: none;

        &:nth-child(even) {
            background-color: rgba($grey, .05);
        }
    }

    &__data,
    &__cell {
        padding: $space--xxxs;
        border: none;
    }
}

/*=====  End of Table  ======*/

