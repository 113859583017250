/*=============================================
=            Category and Filter Bar            =
=============================================*/

.category-and-filter-bar {
    padding: $space--lg $space--xxl;
	background-color: $bg--white--alt;
    color: rgba($text--dark, .5);

    @include media-breakpoint-down(md) {
        padding: $space--md;
    }

    &__content {
        align-items: center;
    }

    &__tab {
        display: inline-block;
        margin-right: $space--xs;
        font-size: $font-size--xs;
        cursor: pointer;

        &--active {
            color: $text--dark;
            padding-bottom: $space--xxxs;
            border-bottom: 3px solid $black;
        }
    }

    &__search {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        column-gap: $space--xs;

        @include media-breakpoint-down(md) {
            display: block;
        }

        .field {
            display: flex;

            @include media-breakpoint-down(md) {
                display: block;
            }

            &__label {
                color: rgba($text--dark, .5);
                margin: $space--xxs $space--xs 0 0;

                @include media-breakpoint-down(md) {
                    margin-bottom: $space--xs;
                }
            }

            &__el {
                color: $text--dark;
            }
        }
    }
}

/*=====  End of Category and Filter Bar  ======*/
