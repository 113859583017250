/*=============================================
=            News and Events            =
=============================================*/

.news-and-events {
    padding: $space--xxl;

    @include media-breakpoint-down(lg) {
        padding: $space--xxl $space--md;
    }

    &__title {
        @include media-breakpoint-down(sm) {
            margin-bottom: $space--xxs;
        }
    }

    &__buttons {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .button {
            height: 30px;
        }
    }

	&__article {
        position: relative;
        overflow: hidden;

        &-wrapper {
            margin: $space--md 0 0 0;
            background-color: $bg--white;

            @include media-breakpoint-down(sm) {
                margin: $space--md 0 0 0;
            }

        }

        &-info {
            display: flex;
            @include BodyCopyXs;
        }

        &-image-wrapper {
            overflow: hidden;
            @include transition;
        }

        &-image {
            height: 200px;
            object-fit: cover;
        }

        &-content {
            position: relative;
            background-color: $white;
            padding: $space--xs $space--xs $space--lg $space--xs;
        }

        &-tag {
            color: $text--primary--dark;
        }

        &-date {
            color: $text--grey;
            text-transform: uppercase;
        }

        &-tag,
        &-date,
        &-location {
            margin-right: $space--xxxs;
        }

        &-title {
            margin-top: $space--xxxs;
            @include BodyCopyLg;
        }

        .zmdi {
            position: absolute;
            color: $text--primary;
            opacity: 0;
            right: 50%;
            bottom: $space--xxxs;

            @include transition;
        }

        &:hover {
            .news-and-events__article-image-wrapper {
                transform: scale(1.2);
            }

            .zmdi {
                right: 15px;
                opacity: 1;
            }
        }
    }

    &.bg-white {
        .news-and-events__article-wrapper,
        .news-and-events__article-content {
            background-color: $bg--white--alt;
        }
    }

    &.bg-blue {
        .button--primary.button--text-link,
        .heading {
            color: $text--light;
        }
    }
}

/*=====  End of News and Events  ======*/
