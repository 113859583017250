/*=============================================
=            Media with text            =
=============================================*/

.media-with-text {
    position: relative;
    min-height: 560px;
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto;

    &--bg {
        &-grey {
            background-color: $bg--white--alt;
            color: $text--dark;
        }

        &-blue {
            background-color: $bg--primary;
            color: $text--light;

            .media-with-text__content--title {
                color: $text--light;
            }

            .rte,
            .rte.fr-view p {
                color: $text--light;
            }

            .button {
                background-color: $bg--black;
            }
        }
    }

    &--full-bleed {
        max-width: unset;

        .media-with-text__content {
            padding: $space--xxxl;
            max-width: 800px;
        }

        @include media-breakpoint-up(md) {
            .col--full-bleed {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

	&__content {
        padding: $space--xxxxl;

        @include media-breakpoint-down(md) {
            padding: $space--md;
        }

        &--full-bleed {
            padding: $space--xxxxl;

            @include media-breakpoint-down(md) {
                padding: $space--md;
            }
        }

        &--title {
            padding-bottom: $space--md;
            color: $grey;
            @include DesktopH2;

            @include media-breakpoint-down(sm) {
                @include DesktopH3;
            }
        }

        &--description {
            @include media-breakpoint-up(md) {
                padding-bottom: $space--lg;
            }

            @include BodyCopySm;
        }
    }

    &__form {
        padding: $space--xxl;
        background: $bg--white--alt;
        margin-right: $space--lg;
        color: $text--dark;

        form {
            margin-top: unset;

            @include media-breakpoint-down(md) {
                margin-top: unset;
            }
        }
    }

    &__image {
        padding: $space--xxxxl 0 $space--xxxxl $space--xxxxl;

        &--full-bleed {

            &__right {
                padding: 0;
            }
            
            @include media-breakpoint-down(md) {
                left: unset;
                width: 100%;
                position: relative;
            }
        }

        &:hover {
            .media-with-text__image--overlay {
                visibility: visible;
                opacity: 1;
            }

            .media-with-text__image--overlay__text {
                visibility: visible;
                opacity: 1;
            }
        }

        @include media-breakpoint-down(md) {
            padding: $space--md $space--md 0 $space--md;
        }

        &--full-bleed {
            padding: 0;
        }

        &--wrapper {
            position: relative;
        }

        &--overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($bg--black, .44);
            color: #fff;
            visibility: hidden;
            opacity: 0;
            @include transition;

            &__text {
                position: absolute;
                bottom: 0;
                padding: $space--md;
                color: #fff;
                visibility: hidden;
                opacity: 0;
                @include transition;
            }
        }
    }

    .rte {
        padding: 0;
    }

    .rte p {
        color: $text--grey;
    }
}

.flip-column {
    order: 1;

    .media-with-text__form {
        margin-right: 0;
    }
}

/*=====  End of Media with text  ======*/
