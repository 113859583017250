.bg {
    &-grey {
        background-color: $bg--white--alt;
    }

    &-blue {
        background-color: $bg--primary;
    }

    &-white {
        background-color: $bg--white;
    }
}