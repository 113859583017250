@font-face {
    font-family: 'Gotham';
    src:
        url('/assets/fonts/Gotham/Black/Gotham-Black.woff') format('woff'),
        url('/assets/fonts/Gotham/Black/Gotham-Black.ttf') format('truetype'),
        url('/assets/fonts/Gotham/Black/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src:
        url('/assets/fonts/Gotham/Bold/Gotham-Bold.woff') format('woff'),
        url('/assets/fonts/Gotham/Bold/Gotham-Bold.ttf') format('truetype'),
        url('/assets/fonts/Gotham/Bold/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src:
        url('/assets/fonts/Gotham/Book/Gotham-Book.woff') format('woff'),
        url('/assets/fonts/Gotham/Book/Gotham-Book.ttf') format('truetype'),
        url('/assets/fonts/Gotham/Book/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: 300;
    font-style: normal;
}
