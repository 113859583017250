/*=============================================
=            Cookie banner            =
=============================================*/

.cookie-banner {
	display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    color: $text--light;
    background-color: $bg--black;
    padding: $space--md $space--xxl $space--md $space--xxl;
    z-index: 100;

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
        padding: $space--xs;
    }

    &__text {
        margin-right: $space--md;

        @include media-breakpoint-down(sm) {
            margin-right: 0;
            margin-bottom: $space--sm;
        }
    }

    &-input {
        background-color: $bg--white;
        margin-right: $space--md;
        height: 40px;
        padding: $space--xxxs;
    }

    &__buttons {
        display: flex;
        justify-content: center;
    }

    @include DesktopH4;

    &__accept {
        margin-right: $space--md;
    }

    &__reject {
        background-color: $bg--grey--light;
    }
}

/*=====  End of Cookie banner  ======*/
