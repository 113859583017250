/*=============================================
=            Form            =
=============================================*/

.form-field {
  align-items: center;
  margin-bottom: $space--sm;
  display: block;

  @include media-breakpoint-up(xl) {
    display: flex;
  }

  &--hidden {
    display: none;
  }

  .control-label {
    display: block;
    margin-bottom: $space--tiny;
    margin-right: $space--xs;
    width: 200px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      width: auto;
    }
  }

  .form-control {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: 2px solid $border--dark;
    padding: 0 $space--xxs;
    font-weight: $font-weight--normal;
    font-family: $font-family--base;

    @include transition;

    &:focus {
      border-color: $border--primary;
      outline: none;
      box-shadow: none;
    }
  }

  .form-control[type="file"] {
    display: none;
  }

  label[title="Please select a file"] {
    @extend .button;
    @extend .button--primary;
    line-height: 40px;
    border: 2px solid $border--dark;
    padding: 0 $space--xxs;
    font-weight: $font-weight--normal;
    font-family: $font-family--base;
  }

  .ktc-uploader-text-button {
    display: none;
  }

  textarea.form-control {
    height: 100px;
    resize: none;
  }

  .editing-form-control-nested-control {
    flex: 1;
  }

  .ktc-checkbox,
  .ktc-radio {
    display: block;
    cursor: pointer;
    margin-top: 10px;

    &:first-child {
      margin: 0;
    }

    label {
      display: block;
      align-items: center;
      cursor: pointer;

      &::before {
        content: '';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: $space--xxs;
        width: 30px;
        height: 30px;
        border: 2px solid $border--dark;
        font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
        content: '\f26b';
        color: $text--light;

        @include transition;
      }
    }

    .ktc-radio {
      label {
        &::before {
          border-radius: 50%;
        }
      }
    }

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked ~ label::before {
        border-color: $border--primary;
        background-color: $bg--primary;
      }
    }
  }
}

.form-title,
.form-intro {
  margin-bottom: $space--sm;
  display: block;
}

.ktc-checkbox {
  input[type="checkbox"] {
    width: 0;
  }
}

input[type="submit"] {
  @extend .button;
  @extend .button--primary;

  font-family: $font-family--base;
}

.field-validation-error {
  color: red;
  font-size: 12px;
  margin-top: 8px;
}

form {
  max-width: $container-width--narrow;
  margin: 0 auto;
  width: 100%;
  margin-top: 60px;

  @include media-breakpoint-down(md) {
      margin-top: 30px;
  }
}

/*=====  End of Form  ======*/
