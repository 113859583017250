$space--mega: 100px;
$space--xxxxl: 80px;
$space--xxxl: 70px;
$space--xxl: 60px;
$space--xl: 50px;
$space--lg: 40px;
$space--md: 30px;
$space--sm: 26px;
$space--xs: 20px;
$space--xxs: 16px;
$space--xxxs: 10px;
$space--tiny: 5px;

.space-xs--top {
    margin-top: $space--xs;
}
