/*=============================================
=            Footer            =
=============================================*/

.footer {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($bg--black, .8);
        z-index: -1;
    }

    &__form {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $bg--black;
        color: $text--light;
        padding: $space--md $space--xxl $space--md $space--xxl;

        &-text {
            margin-right: $space--md;
        }

        &-input {
            background-color: $bg--white;
            margin-right: $space--md;
            height: 40px;
            padding: $space--xxxs;
            width: 220px;
            color: $text--dark;
        }

        @include DesktopH4;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        form {
            max-width: none;
            margin: unset;
            width: unset;
        }
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -2;

        img {
            @include media-breakpoint-down(lg) {
                width: auto;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &__main {
        padding: $space--xxl $space--xxl $space--mega $space--xxl;
        color: $text--light;
        @include BodyCopyXs;

        @include media-breakpoint-down(sm) {
            padding: $space--md;
            line-height: $line-height--sm;
        }
    }

    &__logo {
        display: block;
        margin-bottom: $space--lg;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }

    &__title {
        margin-bottom: $space--xs;
        @include DesktopH4;

        @include media-breakpoint-down(md) {
            margin-top: $space--lg;
        }
    }

    &__links {
        @include media-breakpoint-down(sm) {
            margin-right: $space--md;
        }

        li {
            margin-bottom: $space--xxxs;
        }

        a {
            display: block;
        }

        &--inline {
            li {
                display: inline-block;
                margin-right: $space--md;
                margin-bottom: 0;

                @include media-breakpoint-down(sm) {
                    display: block;
                    margin: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                line-height: $line-height--lg;
            }
        }

        &--space-top {
            margin-top: $space--xxxxl;
        }

        &--social {
            position: relative;
            width: fit-content;
            margin-left: auto;
            font-size: $font-size--lg;
            text-align: right;

            @include media-breakpoint-down(lg) {
                margin-left: 0;
            }

            .twitter-x {
                transform: translateY(4px);
            }

            li {
                display: inline-block;
                margin-right: 0;
                margin-left: $space--xs;
                @include media-breakpoint-down(lg) {
                    margin-left: 0;
                    margin-right: $space--xxs;
                    padding: $space--xs 0 $space--md 0;
                }
            }
            @include media-breakpoint-down(lg) {
                text-align: left;
            }
        }
    }

    &__net-zero {
        aspect-ratio: 1/1;
        width: 90px;
        
        &--desktop {
            position: absolute;
            width: 90px;
            top: -30px;
            left: -55%;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        &--mobile {
            margin-top: 30px;

            @include media-breakpoint-up(xl) {
                display: none;
            }
        }
    }

    .simpleParallax {
        height: 100%;
    }

    .zmdi-tiktok {
        display: inline-block;
        background: url('/assets/img/tiktok.svg');
        background-position: center;
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        filter: invert(1);
    }
}

/*=====  End of Footer  ======*/
