/*=============================================
=            Card List            =
=============================================*/

.card-list {
    padding: $space--xxl;

    @include media-breakpoint-down(lg) {
        padding: $space--xxl $space--md;
    }

    &__title {
        @include media-breakpoint-down(sm) {
            margin-bottom: $space--xxs;
        }
    }

	&__card {
        position: relative;
        margin: $space--md 0 0 0;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            margin: $space--md 0 0 0;
        }

        &-image {
            overflow: hidden;
            @include transition;

            &--wrapper {
                position: relative;
                height: 215px;
                object-fit: cover;
                display: flex;
            }
        }

        &-content {
            position: relative;
            padding: $space--xs 0;
        }

        &-description {
            padding-top: $space--xs;

            @include BodyCopySm;
        }

        &-title {
            @include DesktopH4;
        }

        &-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($bg--black, .44);
            color: #fff;
            visibility: hidden;
            opacity: 0;
            @include transition;

            &__text {
                position: absolute;
                bottom: 0;
                padding: $space--md;
                color: #fff;
                visibility: hidden;
                opacity: 0;
                @include transition;
            }
        }

        &:hover {
            .card-list__card-overlay {
                visibility: visible;
                opacity: 1;
            }

            .card-list__card-overlay__text {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

/*=====  End of Card List  ======*/
