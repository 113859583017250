/*=============================================
=            Page CTA            =
=============================================*/

.page-cta {
    &__wrapper {
        background-color: $white-alt;
        padding: $space--xxl;

        @include media-breakpoint-down(lg) {
            padding: $space--md $space--md 0 $space--md;
        }
    }

    &__cards  {
        display: flex;
        flex-wrap: wrap;
    }

	&__card {
        color: $white;
        position: relative;
        text-align: left;
        height: 250px;
        overflow: hidden;

        &--wrapper {
            flex: 1 1 calc(30% - 1px);
            margin: $space--xxs;

            @include media-breakpoint-down(sm) {
                flex: 100%;
                margin: $space--xxxs;
            }
        }

        @include media-breakpoint-down(sm) {
            height: 170px;
        }

        &--no-bg {
            background-color: $white;
            color: $text--primary;

            .page-cta__card--wrapper {
                min-height: 250px;
            }
        }

        &--content {
            position: absolute;
            top: 30px;
            left: 30px;
            padding-right: $space--xl;

            @include transition;

            @include media-breakpoint-down(md) {
                padding-right: $space--mega;
            }

            @include media-breakpoint-down(md) {
                padding-right: $space--xl;
            }
        }

        &--overlay {
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 1;

                @include transition;
            }

            &::before {
                background: rgba($bg--black, 0.8);
            }

            &::after {
                background: linear-gradient(90deg, rgba($bg--primary, 0.6) 0%, rgba($bg--primary, 0.3) 100%), rgba($bg--black, 0.3);
            }
        }

        &--background {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;

            @include transition;
        }

        &--title {
            margin-bottom: $space--xs;

            @include DesktopH3;

            @include media-breakpoint-down(md) {

                @include DesktopH3Sm;
            }
        }

        &--description {
            font-size: $font-size--xs;
            line-height: $line-height--lg;
        }

        &--blue-rectangle {
            background-color: $bg--primary;
            width: 40px;
            position: absolute;
            height: 100%;
            right: 0;
            opacity: 0;
            z-index: 50;
            @include transition;

            @include media-breakpoint-down(lg) {
                opacity: 1;
            }
        }

        .zmdi {
            width: 40px;
            position: absolute;
            top: calc(100% - 30px);
            right: 0;
            text-align: center;
            z-index: 51;

            @include transition;

            @include media-breakpoint-down(lg) {
                right: 0;
                top: 50%;
                color: $white;
                z-index: 9999;
            }
        }

        &:hover {
            .page-cta__card--content {
                padding-left: $space--xxxs;

                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                }
            }

            .page-cta__card--overlay {
                &::after {
                    opacity: 0;
                }

                &::before {
                    opacity: 1;
                    background: rgba($bg--black, .44);

                    @include media-breakpoint-down(lg) {
                        background: unset;
                    }
                }
            }

            .zmdi {
                top: 50%;
                color: $white;
            }

            .page-cta__card--background {
                transform: scale(1.2);

                @include media-breakpoint-down(lg) {
                    transform: none;
                }
            }

            .page-cta__card--blue-rectangle {
                opacity: 1;
            }
        }
    }
}

/*=====  End of Page CTA  ======*/
