/*=============================================
=            Container            =
=============================================*/

.container {
	max-width: $container-width--default;
    margin: 0 auto;
    width: 100%;

    &--wide {
        max-width: $container-width--wide;
    }

    &--narrow {
        max-width: $container-width--narrow;
    }
}

/*=====  End of Container  ======*/
