/*=============================================
=            Logo            =
=============================================*/

.logo {
    &--light {
        path {
            fill: $bg--white;
        }
    }

    &--dark {
        path {
            fill: $bg--black;
        }
    }

    &--primary {
        path {
            fill: $bg--primary;
        }
    }
}

/*=====  End of Logo  ======*/
