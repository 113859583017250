/*=============================================
=            Search Results            =
=============================================*/

.search-results {
    position: relative;
    min-height: 560px;
    overflow: hidden;
    max-width: 1150px;
    margin: 0 auto;

    form {
        max-width: none;
        margin: unset;
        width: unset;
        margin-top: unset;

        @include media-breakpoint-down(md) {
            margin-top: unset;
        }
    }

    @include media-breakpoint-down(md) {
        min-height: 275px;
    }

    &__context {
        padding-top: $space--lg;

        @include media-breakpoint-down(md) {
            padding: $space--md;
        }

        &-result {
            padding-top: $space--xs;
        }
    }

	&__content {
        padding: $space--md $space--md $space--md 0;

        @include media-breakpoint-down(md) {
            padding: $space--md;
        }

        &-section {
            padding: 30px 30px 30px 0;

            @include media-breakpoint-down(md) {
                padding: 20px 20px 20px 0;
            }
            @include media-breakpoint-down(xs) {
                padding: 0;
            }
        }

        &-image {
            padding: 30px 100px;

            @include media-breakpoint-down(md) {
                padding: 20px 50px;
            }

            @include media-breakpoint-down(xs) {
                padding: 0;
            }
        }

        &-title {
            padding-bottom: $space--xxs;
            color: $text--primary;
            font-size: 30px;
            font-weight: 700;
        }

        &-description {
            margin: 20px 0;
            @include BodyCopySm;
        }

        &-info {
            display: block;
            align-items: center;
            justify-content: space-between;

            &_icon-margin {
                margin-right: 10px;
            }
        }
    }
}

.search {
    &__main {
        &-search-field {
            height: 41px;
            line-height: 41px;
            border-radius: 41px;
            border: 1px solid rgba(0,0,0,.3);
            width: 305px;
            padding: 0 20px;

            @include media-breakpoint-down(md) {
                width: 250px;
            }
        }

        &-search-btn {
            display: block;
            cursor: pointer;
        }

        &-icon {
            position: relative;
            top: 13px;
            right: 27px;
            cursor: pointer;
        }
    }
}

#on-search-form {
    height: 41px;
    display: flex;

    @include media-breakpoint-down(md) {
        justify-content: center;
    }
}
/*=====  End of Search Results  ======*/
