/*=============================================
=            Button            =
=============================================*/

.button,
%button {
	display: inline-block;
    font-size: $font-size--sm;
    text-transform: uppercase;
    font-weight: $font-weight--bold;
    line-height: $line-height--lg;
    padding: $space--xxxs $space--sm;
    height: $space--lg;
    cursor: pointer;

    @include transition;

    &[disabled] {
        cursor: not-allowed;
        background: rgba($black, .3);

        &:hover {
            background: rgba($black, .3);
        }
    }

    &--primary,
    %--primary {
        background: $bg--primary;
        color: $text--light;

        &:hover {
            background: $bg--black;
            color: $text--light;
        }

        &.button--text-link {
            color: $text--primary--dark;
        }
    }

    &--download {
        @extend .button--primary;

        font-family: $font-family--base;
        font-weight: $font-weight--bold;
    }

    &--secondary {
        background: $bg--secondary;
        color: $text--dark;

        &:hover {
            background: $bg--black;
            color: $text--light;
        }

        &.button--text-link {
            color: $text--secondary;
        }
    }

    &--outline {
        background: transparent;
        color: $text--primary;
        border: 1px solid $border--primary;

        &:hover {
            background: $bg--black;
            color: $text--light;
            border-color: $border--dark;
        }

        &[disabled] {
            color: $text--grey--light;
            border-color: $border--grey--light;
            background: transparent;
        }
    }

    &--text-link,
    %--text-link {
        background: transparent;

        &:hover {
            color: $text--dark;
            background: transparent;
        }
    }

    &--text-link-light,
    %--text-link-light {
        color: $text--light;
        background: transparent;

        &:hover {
            color: $text--dark;
            background: transparent;
        }
    }

    &--icon {
        .zmdi {
            margin-left: $space--xxs;
        }

        &-only {
            width: 40px;
            padding: 0;
            text-align: center;
            line-height: 40px;

            .zmdi {
                margin: 0;
            }
        }

        &-animated {
            position: relative;

            .zmdi {
                margin: 0;
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                opacity: 0;

                @include transition;
            }

            &:hover {
                padding-right: $space--lg;

                .zmdi {
                    right: 15px;
                    opacity: 1;
                }
            }

            &.button--icon-prepended {
                .zmdi {
                    right: unset;
                    left: 20px;
                }

                &:hover {
                    padding-right: $space--sm;
                    padding-left: $space--lg;

                    .zmdi {
                        left: 15px;
                    }
                }
            }
        }
    }

    &--padd-x-0 {
        padding-left: 0;
        padding-right: 0;
    }

    &--padd-x-1 {
        padding-left: 0;
        padding-right: $space--md;
    }
}

/*=====  End of Button  ======*/
