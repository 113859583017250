/*=============================================
=            Interactive Demo            =
=============================================*/

.interactive-demo {
	display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: $space--lg;

    &__wrapper {
        padding: $space--xxl;
    }

    &__sidebar {
        position: relative;
        flex: 0 0 465px;
        max-width: 100%;

        &-item {
            position: relative;
            display: grid;
            grid-template-columns: 60px 1fr 1fr;
            grid-template-areas:
                "icon title title"
                "icon content content";
            padding: 0 0 $space--md $space--xs;

            @include transition;

            &::before {
                content: '';
                position: absolute;
                top: 18px;
                left: -4px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $bg--tertiary;
            }

            &:not(:last-child):after {
                content: '';
                position: absolute;
                top: 20px;
                width: 2px;
                height: 100%;
                background: $bg--tertiary;
            }

            &:hover {
                color: $text--tertiary;
                cursor: pointer;
            }
        }

        &-icon {
            grid-area: icon;
            width: 40px;
            font-size: 40px;
        }

        &-title {
            grid-area: title;
            margin-bottom: 10px;

            @include DesktopH4;
        }

        &-text {
            grid-area: content;

            @include BodyCopyXs;
        }
    }

    &__images {
        position: relative;
        flex: 0 0 770px;
        max-width: 100%;
    }

    &__image {
        @include transition;

        &--hover {
            top: 0;
            left: 0;
            position: absolute;
            opacity: 0;
        }
    }
}

/*=====  End of Interactive Demo  ======*/
