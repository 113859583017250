$black: #000;
$white: #fff;
$white-alt: #F3F3F3;

$grey: #3C3C3C;
$grey--light: #B3B3B3;
$grey--alt: #E6E6E6;

$primary--dark: #01558a;
$primary: #0076C0;
$primary--light: #84BFE9;

$secondary: rgb(132, 189, 0);

$tertiary: rgb(255, 130, 0);

// background colours

$bg--black: $black;
$bg--white: $white;
$bg--white--alt: $white-alt;
$bg--grey: $grey;
$bg--grey--light: $grey--light;
$bg--grey--alt: $grey--alt;
$bg--white--alt: $white-alt;

$bg--primary: $primary;
$bg--primary--dark: $primary--dark;
$bg--secondary: $secondary;
$bg--tertiary: $tertiary;

// text colours

$text--dark: $black;
$text--light: $white;
$text--grey: $grey;
$text--grey--light: $grey--light;

$text--primary: $primary;
$text--primary--dark: $primary--dark;
$text--secondary: $secondary;
$text--tertiary: $tertiary;

// border colours

$border--dark: $black;
$border--grey--light: $grey--light;
$border--tertiary: $tertiary;
$border--white: $white;

$border--primary: $primary;
