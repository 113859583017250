/*=============================================
=            Category List            =
=============================================*/

@import 'swiper/swiper';

.category-list {
    &__wrapper {
        padding: $space--xxl;
    }

	&__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @include media-breakpoint-down(sm) {
        &__heading {
            margin-bottom: $space--xs;
        }
    }

    &__grid {
        margin-top: $space--md;

        &.grid {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            column-gap: $space--md;
            overflow-x: auto;

            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: $space--sm;
            }
        }

        &.swiper {
            overflow: hidden;
            cursor: pointer;
        }
    }

    &__item {
        display: block;
        margin-bottom: $space--md;
    }

    &__image {
        background: $white-alt;
        margin-bottom: $space--xs;;
        padding: $space--xxxs;

        img {
            width: 195px;
            height: 180px;
        }
    }

    &__title,
    &__sub-title {
        @include BodyCopyXs;
    }

    &__title {
        color: $text--primary;
    }

    &__sub-title {
        color: $grey;
    }

    &__scrollbar {
        background: rgba($bg--black, .1);
        height: 10px;
        width: 100%;

        .swiper-scrollbar-drag {
            background: $bg--primary;
            height: inherit;
        }
    }
}

/*=====  End of Category List  ======*/
