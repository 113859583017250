/*=============================================
=            Select            =
=============================================*/

.field {
	&__label {
        color: $text--light;
        margin-bottom: $space--xxs;

        @include FormLabel;
    }

    &__el {
        background: $bg--white;
        height: $space--lg;
        width: 220px;
        border: none;
        outline: 2px solid rgba($bg--black, .3);
    }
}

/*=====  End of Select  ======*/
