/*=============================================
=            Case Studies            =
=============================================*/

.case-studies {
    padding: $space--xxl;

    @include media-breakpoint-down(sm) {
        padding: $space--md;
    }

    &__heading {
        display: flex;
        justify-content: space-between;
    }

	&__carousel {
        margin-top: $space--md;
        overflow: hidden;

        &-wrapper {
            display: flex;
            align-items: flex-end;
            position: relative;

            &_card {
                align-items: baseline;
                flex-wrap: wrap;
                margin-top: $space--md;
            }
        }

        &-nav {
            margin-left: $space--md;

            @include media-breakpoint-down(md) {
                margin: 0;
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 10;
            }
        }

        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: $bg--primary;
            border: 1px solid $bg--primary;
            color: $text--light;
            cursor: pointer;

            @include transition;

            &:hover {
                background: $bg--black;
                color: $text--light;
            }

            &.swiper-button-disabled {
                background: $bg--white;
                color: $text--primary;
                cursor: not-allowed;
            }
        }
    }

    &__slide {
        display: flex;
        width: 100%;
        background: $bg--white;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }


        &.swiper-slide {
            height: unset;
        }

        &-image {
            position: relative;
            flex: 1;
            max-width: 100%;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-down(md) {
                flex: 0 0 315px;
            }

            &_card {
                flex: 0 0 315px;
            }

            &_single {
                img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &-card {
            flex-direction: column;
            flex: 0 0 32.3333%;
            margin: 0 $space--xxxs $space--xxxs 0;
            align-self: stretch;

            @include media-breakpoint-down(lg) {
                flex: 0 0 48%;
            }

            @include media-breakpoint-down(md) {
                flex: 100%;
            }

            &-wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            &_button {
                padding: 0 0 $space--lg $space--md;
            }
        }

        &-content {
            flex: 0 0 1;
            width: 100%;
            max-width: 670px;
            position: relative;
            padding: $space--xxxxl $space--xxl;

            @include media-breakpoint-down(md) {
                max-width: 100%;
                padding: $space--md $space--md $space--xxl $space--md;
            }

            &_card {
                max-width: 100%;
                padding: $space--md $space--md 0 $space--md;
            }
        }

        &-title {
            color: $text--primary;

            @include DesktopH3;
            font-weight: $font-weight--black;

            @include media-breakpoint-down(sm) {
                @include DesktopH3Sm;
            }
        }

        &-sub-title {
            margin-top: $space--xxxs;
            @include BodyCopySm;
        }

        &-stats {
            display: flex;
            justify-content: flex-start;
            margin-top: $space--xs;
            @include BodyCopySm;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
                border-top: 1px solid rgba($bg--grey, .4);
                padding-top: 5px;
            }

            &__carousel {
                flex-wrap: wrap;
            }
        }

        &-stat {
            margin-bottom: $space--tiny;
            border-right: 1px solid rgba($bg--grey, .4);
            padding: 0 $space--xs;

            @include media-breakpoint-down(sm) {
                display: flex;
                flex: 50%;
                padding: 0;

                &:nth-child(2) {
                    padding-left: $space--xxxs;
                    border: none;
                }
            }

            strong {
                display: block;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;

                @include media-breakpoint-down(sm) {
                    padding: $space--tiny 0;
                    border-top: 1px solid rgba($bg--grey, .4);
                    border-bottom: 1px solid rgba($bg--grey, .4);
                }
            }

            &_card {
                display: flex;
                flex: 50%;
                padding: 0;

                &:nth-child(2) {
                    padding-left: $space--xxxs;
                    border: none;
                }

                &:last-child {
                    padding: $space--tiny 0;
                    border-top: 1px solid rgba($bg--grey, .4);
                    border-bottom: 1px solid rgba($bg--grey, .4);
                }
            }
        }

        &-applications {
            margin-top: $space--xs;

            &_card {
                margin-top: 0;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }

            &-title {
                margin-bottom: $space--tiny;

                &_card {
                    margin-bottom: 0;
                }

                @include BodyCopyXs;
            }

            &-list {
                display: flex;
                flex-wrap: wrap;
            }

            &-item {
                margin-right: $space--xxxs;
                border: 1px solid $bg--grey;
                border-radius: 28px;
                padding: 0 $space--xxxs;
                height: 28px;

                @include BodyCopySm;
                line-height: 28px;

                &_card {
                    margin-right: 0;
                    border: none;
                    padding: 0;
                    font-weight: 700;
                }
            }
        }

        &-body {
            margin-top: $space--xs;
            max-width: 470px;

            &_card {
                margin-bottom: $space--sm;
            }

            @include BodyCopySm;
        }

        &-link {
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            background: $bg--primary;
            color: $text--light;

            @include media-breakpoint-down(md) {
                width: 40px;
                height: 40px;
            }

            &:hover {
                background: $bg--black;
                color: $text--light;
            }
        }
    }

    &.bg-white {
        .case-studies__slide-content_card,
        .case-studies__slide-card_button,
        .case-studies__slide-card-wrapper {
            background-color: $bg--white--alt;
        }
    }
}

/*=====  End of Case Studies  ======*/
