/*=============================================
=            Stat Block            =
=============================================*/

.stat-block {
    position: absolute;
    top: 50%;
    left: 0%;
    right: 0%;
    margin: 0 auto;
    width: 100%;
    max-width: $container-width--default;
    transform: translateY(-50%);
    text-align: center;
    color: $text--light;
    z-index: 2;

    &__wrapper {
        position: relative;
        z-index: 1;
        overflow: hidden;
        min-height: $parallax-height--default;

        @include media-breakpoint-up(xl) {
            max-height: $parallax-height--default;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(0deg, rgba($bg--black, .6), rgba($bg--black, .6));
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: $space--lg;

        &__item {
            margin-bottom: $space--lg;
        }
    }

    &__stat,
    &__label {
        margin-bottom: $space--xxxs;
    }

    &__stat,
    &__prefix,
    &__suffix {
        letter-spacing: 5px;

        @include DesktopH1Mega;
    }

    &__label {
        @include DesktopH4;
    }

    &__text {
        opacity: .5;

        @include BodyCopyXs;
    }

    &__figure {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__prefix,
    &__suffix {
        transform: translateY(-7px);
    }
}

/*=====  End of Stat Block  ======*/
