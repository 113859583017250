/*=============================================
=            Category Grid            =
=============================================*/

@use 'sass:math';

$gutter: 10px;
$carouselBreakpoint: 645px;

.category-grid {
    &--desktop {
        display: block;

        @media (max-width: $carouselBreakpoint) {
            display: none;
        }
    }

    &--mobile {
        display: none;

        @media (max-width: $carouselBreakpoint) {
            display: block;
        }
    }

	&__wrapper {
        padding: $space--xxl;
        background: $bg--grey;

        @media (max-width: $carouselBreakpoint) {
            padding: $space--sm;
        }

        &--primary {
            background: $bg--primary;
        }

        &--light {
            background: $bg--white;
            
            .heading--light {
                color: $text--grey;
            }
        }

        .heading {
            margin-bottom: $space--md;

            @include media-breakpoint-down(lg) {
                @include DesktopH3;
            }
        }

        .grid-sizer {
            width: math.div(100%, 6);

            @include media-breakpoint-down(lg) {
                width: math.div(100%, 4);
            }

            @include media-breakpoint-down(md) {
                width: math.div(100%, 3);
            }
        }
    }

    &__content {
        position: absolute;
        bottom: $space--xs;
        left: $space--xs;
        z-index: 2;
        width: calc(100% - $space--xs * 2);
        word-wrap: break-word;
        color: $text--light;
        transform: translateY($space--lg);

        @include transition;
    }

    &__heading,
    &__text {
        margin-bottom: $space--xxxs;
    }

    &__text {
        opacity: 0;

        @include BodyCopySm;
        @include transition;
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__item {
        position: relative;
        display: inline-block;
        margin-bottom: $space--xxxs;
        overflow: hidden;

        @media (max-width: $carouselBreakpoint) {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($bg--black, .15);
            z-index: 1;

            @include transition;
        }

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        &--sm {
            width: calc(100% / 6 - $gutter);

            @include media-breakpoint-down(lg) {
                width: calc(100% / 4 - $gutter);
            }

            @include media-breakpoint-down(md) {
                width: calc(100% / 3 - $gutter);
            }

            @media (max-width: $carouselBreakpoint) {
                width: 100%;
            }

            .category-grid__heading {
                text-transform: uppercase;

                @include DesktopH3Sm;

                @include media-breakpoint-down(md) {
                    @include DesktopH4;
                }

                @media (max-width: $carouselBreakpoint) {
                   @include DesktopH3Sm;
                }
            }
        }

        &--lg,
        &--wide {
            width: calc(100% / 6 * 2 - $gutter);

            @include media-breakpoint-down(lg) {
                width: calc(100% / 4 * 2 - $gutter);
            }

            @include media-breakpoint-down(md) {
                width: calc(100% / 3 * 2 - $gutter);
            }

            @media (max-width: $carouselBreakpoint) {
                width: 100%;
            }

            .category-grid__heading {
                @include DesktopH3;

                @include media-breakpoint-down(md) {
                    @include DesktopH3Sm;
                }
            }
        }

        &--wide {
            &::after {
                padding-bottom: 50%;
            }
        }

        .zmdi {
            position: absolute;
            bottom: 15px;
            left: 15px;
            width: 16px;
            text-align: right;
            color: $text--light;
            opacity: 0;
            z-index: 1;

            @include transition;
        }

        &:hover {
            .category-grid {
                &__content {
                    transform: translateY(-20px);
                }

                &__text {
                    opacity: 1;
                }
            }

            &::before {
                background: rgba($bg--primary, .7);
            }

            .zmdi {
                width: 100%;
                max-width: calc(100% - 30px);
                opacity: 1;
            }
        }
    }
}

/*=====  End of Category Grid  ======*/
