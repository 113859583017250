/*=============================================
=            Quote Banner            =
=============================================*/

.quote-banner {
    position: relative;
	background-color: $bg--primary;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;

    &__wrapper {
        position: relative;
        max-width: 980px;
        margin: 0 auto;
        padding: $space--xxl;
        color: $text--light;
        text-align: center;
    }

    &__title {
        font-size: $font-size--quote;
        padding-bottom: $space--xs;

        &-uppercase {
            @include DesktopH2;
        }
    }

    &__subtitle {
        font-size: $font-size--xs;
        padding-bottom: $space--xs;
    }

    &__overlay {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba($bg--black, .7);
        }
    }
}

/*=====  End of Quote Banner  ======*/
