/*=============================================
=            Timeline Carousel            =
=============================================*/

.timeline-carousel {
    padding: $space--xxl;
        .timeline-carousel__content {
            padding: $space--xxxl 0;
            max-width: 800px;

            @include media-breakpoint-down(md) {
                padding: 0 0 0 $space--xs;
            }
        }
    
    &__cards {
        display: flex;

        @include media-breakpoint-down(md) {
            margin-top: $space--md;

            .swiper-wrapper {
                display: block;
            }
        }
    }

	&__content {
        padding: $space--xxxxl 0;

        @include media-breakpoint-down(md) {
            padding: $space--md;
            border-left: 2px solid $border--tertiary;
        }

        &--title {
            position: relative;
            padding: $space--sm 0 $space--xxxs 0;
            border-top: 2px solid $border--tertiary;
            color: $text--grey;
            @include DesktopH2;

            @include media-breakpoint-down(md) {
                padding: 0;
            }

            @include media-breakpoint-down(md) {
                @include DesktopH3;
                border-top: none;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                transform: translate(-50%, -50%);
                z-index: 1;
                width: 10px;
                height: 10px;
                border: 1px solid $border--tertiary;
                border-radius: 50%;
                background: $bg--tertiary;

                @include media-breakpoint-down(md) {
                    left: -21px;
                }
            }
        }

        &--description {
            padding-bottom: $space--lg;
            @include BodyCopySm;

            @include media-breakpoint-down(md) {
                flex: 100%;
                padding-bottom: $space--xxs;
            }
        }
    }

    &__text {
        display: flex;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
    }

    &__image {
        padding: 0 $space--xxxl 0 $space--md;

        &:hover {
            .timeline-carousel__image--overlay {
                visibility: visible;
                opacity: 1;
            }

            .timeline-carousel__image--overlay__text {
                visibility: visible;
                opacity: 1;
            }
        }

        @include media-breakpoint-down(md) {
            padding: 0 $space--md $space--xxs $space--md;
        }

        img {
            width: 200px;
            height: 200px;
        }

        &--wrapper {
            position: relative;
        }

        &--overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($bg--black, .44);
            color: $bg--white;
            visibility: hidden;
            opacity: 0;
            @include transition;

            &__text {
                position: absolute;
                bottom: 0;
                padding: $space--tiny;
                color: $bg--white;
                visibility: hidden;
                opacity: 0;
                @include transition;
            }
        }
    }

    .rte {
        padding: 0;
    }

    .rte p {
        color: $text--grey;
    }

    &__carousel {
        margin-top: $space--md;
        overflow: hidden;

        &-wrapper {
            display: flex;
            align-items: flex-end;
            position: relative;
        }

        &-nav {
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: $bg--primary;
            border: 1px solid $border--primary;
            color: $text--light;
            cursor: pointer;

            @include transition;

            &:hover {
                background: $bg--black;
                color: $text--light;
            }

            &.swiper-button-disabled {
                background: $bg--white;
                color: $text--primary;
                cursor: not-allowed;
            }
        }
    }
}

/*=====  End of Timeline Carousel  ======*/
