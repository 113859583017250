/*=============================================
=            Video            =
=============================================*/

.video {
    &__grey-container {
        background-color: $white-alt;
        padding: $space--sm 0;

        @include media-breakpoint-down(md) {
            padding: $space--md;
        }
    }

    &__blue-container {
        background-color: $bg--primary;
        padding: $space--sm 0;
        color: $text--light;
    }

    &__white-container {
        padding: $space--sm 0;
    }

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    &__panel {
        height: 460px;

        @include media-breakpoint-down(md) {
            height: auto;
            width: 100%;
        }

        &--youtube {
            height: 460px;
            width: 820px;

            @include media-breakpoint-down(sm) {
                height: 100%;
                width: 100%;
            }
        }

        &--default {
            padding: $space--xxxxl;

            @include media-breakpoint-down(md) {
                padding: $space--md $space--md 0 $space--md;
            }
        }

        &--full-bleed {
            padding: 0;
            height: unset;
            width: 100%;
        }

        &-hero {
            object-fit: cover;
        }
    }

    &__title {
        text-align: center;
        padding: $space--md 0;
        @include DesktopH3;
    }

    &__media {
        position: relative;
        display: flex;
        justify-content: center;

        &--play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
            background-image: url('/assets/img/play.svg');
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            background-color: $bg--primary;
            z-index: 10;
            color: $white;
            @include transition;

            &.active {
                opacity: 0;
                visibility: hidden;
                background-image: none;
            }
        }

        &--description {
            padding: $space--md 0;
            text-align: center;
        }

        &--full-bleed {
            position: absolute;
            left: 0;
            width: 50%;
            object-fit: cover;

            @include media-breakpoint-down(lg) {
                position: relative;
                left: unset;
                width: auto;
            }

            &__right {
                position: absolute;
                left: unset;
                right: 0;
                width: 50%;

                @include media-breakpoint-down(lg) {
                    position: relative;
                    right: unset;
                    width: auto;
                }
            }
        }

        &--video {
            width: 100%;

            video {
                width: 100% !important;
                height: auto !important;
            }

            .video__media--iframe-tag {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .video__panel--youtube {
        padding: $space--xxxxl;
    }
}

/*=====  End of Video  ======*/
