/*=============================================
=            Contact Form            =
=============================================*/

.contact-form {
	&__header {
    padding: $space--xxl $space--xxl $space--sm $space--xxl;
    background: $bg--white--alt;

    @include media-breakpoint-down(md) {
      padding: $space--xxl $space--sm $space--sm;
    }
  }

  &__heading {
    margin-bottom: $space--md;
    text-align: center;

    .heading__sub {
      margin-top: $space--xs;
    }
  }

  &__nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -$space--xxxs;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      flex: 1;
      margin: 0 $space--xxxs;
      padding: $space--lg $space--xxxs $space--xs $space--xxs;
      background: $bg--white;
      color: $text--primary;
      cursor: pointer;

      @include transition;

      @include media-breakpoint-down(lg) {
        width: calc(25% - $space--xs);
        margin-bottom: $space--xs;
      }

      @include media-breakpoint-down(md) {
        width: calc(33.06% - $space--xs);
        padding: $space--xxs;
      }

      @include media-breakpoint-down(md) {
        width: calc(50% - $space--xs);
      }

      &-icon {
        position: relative;
        width: 48px;
        height: 48px;
        margin-bottom: $space--xxs;

        @include media-breakpoint-down(md) {
          width: 38px;
          height: 38px;
        }

        &--inactive,
        &--active {
          position: absolute;
          top: 0;

          @include transition;
        }

        &--active {
          opacity: 0;
        }
      }

      &-label {
        font-weight: bold;
        text-transform: uppercase;
        // min-height: 40px;

        @include BodyCopy;

        @include media-breakpoint-down(md) {
          @include BodyCopySm;
        }
      }

      &--active,
      &:hover {
        background: $bg--primary;
        color: $text--light;

        .contact-form__nav-item-icon {
          &--inactive {
            opacity: 0;
          }

          &--active {
            opacity: 1;
          }
        }
      }
    }
  }

  &__wrapper {
    padding: $space--xxl;

    @include media-breakpoint-down(md) {
      padding: $space--sm;
    }
  }

  &__single {
    display: none;

    &--active {
      display: block;
    }
  }
}

/*=====  End of Contact Form  ======*/
