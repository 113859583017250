/*=============================================
=            CTA Banner            =
=============================================*/

.cta-banner {
    text-align: center;
    padding: $space--xxl 0;
    color: $text--grey;

    &__title,
    &__content {
        margin-bottom: $space--xs;
    }

    &__title {
        @include DesktopH2;
    }

    &__content {
        @include BodyCopySm;
    }

    &__spacer {
        position: relative;
        height: 50px;
        width: 10px;
        margin: 0 auto $space--xs auto;

        &:before,
        &:after {
            content: '';
            display: block;
            background: $bg--secondary;
        }

        &::before {
            width: 2px;
            height: 100%;
            margin: 0 auto;
        }

        &::after {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__form {
        margin-bottom: $space--xl;
        height: 0;
        overflow: hidden;
        padding: 0 $space--md;

        @include transition;

        form {
            margin-top: unset;

            @include media-breakpoint-down(md) {
                margin-top: unset;
            }
        }

        &-toggle {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            &--open {
                opacity: 1;
                z-index: 1;
            }

            &--close {
                opacity: 0;
            }

            &-wrapper {
                position: relative;
                min-height: 40px;
            }
        }

        &--open {
            .cta-banner__form-toggle--open {
                opacity: 0;
            }

            .cta-banner__form-toggle--close {
                opacity: 1;
            }
        }
    }
}

/*=====  End of CTA Banner  ======*/
