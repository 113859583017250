/*=============================================
=            Heading            =
=============================================*/

.heading {
	font-family: $font-family--base;
    color: $text--grey;

    &--xl {
        @include DesktopH1;
    }

    &--lg {
        @include DesktopH2;
    }

    &--md {
        @include DesktopH3;
    }

    &--sm {
        @include DesktopH4;
    }

    &__sub {
        margin-top: $space--xxxs;
        color: $text--dark;

        @include BodyCopySm;
    }

    &--light {
        color: $text--light;
    }
}

/*=====  End of Heading  ======*/
