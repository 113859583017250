/*=============================================
=            Location            =
=============================================*/

.location {
	display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  &__map {
    height: 300px;

    @include media-breakpoint-up(lg) {
      flex: 1;
      height: unset;
    }
  }

  &__list {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      display: block;
      max-width: 450px;
    }

    &:hover {
      .location__item--highlight {
        background: $bg--white;
        color: $text--dark;
      }
    }
  }

  &__item {
    flex: 0 0 100%;
    padding: $space--md;
    background: $bg--white;
    color: $text--dark;

    @include transition;

    &:hover {
      background: $bg--primary;
      color: $text--light;

      .location__item-link {
        color: $text--light;
      }
    }

    @include media-breakpoint-up(md) {
      flex: 0 0 50%;
    }

    @include media-breakpoint-up(lg) {
      flex: unset;
    }

    &--highlight {
      background: $bg--primary;
      color: $text--light;

      @include transition;
    }

    &-name {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: $space--xxxs;

      @include BodyCopy;
    }

    &-phone {
      margin-bottom: $space--xs;
    }

    &-address {
      margin-bottom: $space--xs;
    }

    &-link {
      .zmdi {
        margin-left: $space--xxxs;
      }
    }
  }
}
/*=====  End of Location  ======*/
