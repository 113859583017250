/*=============================================
=			Hero			=
=============================================*/

@import 'swiper/swiper';

.hero {
    position: relative;

    form {
        max-width: none;
        margin-top: unset;

        @include media-breakpoint-down(md) {
            margin-top: unset;
        }
    }

    &__carousel {
        overflow: hidden;

        &_slide {
            position: relative;
            z-index: 1;
            height: 600px;

            &--small {
                height: 450px;

                @include media-breakpoint-down(md) {
                    height: calc(100vh - 130px);
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 100%;
                background: linear-gradient(90deg, rgba($bg--black, 0.4) 0%, rgba($bg--black, 0) 100%);
                z-index: -1;
            }

            &__img {
                position: absolute;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                z-index: -2;
            }
        }
    }

    &__overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 1;

        &_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $text--light;
            max-width: 660px;
            padding-left: $space--xxl;
            padding-bottom: $space--xxl;

            .button {
                display: block;
                width: fit-content;
                margin-top: $space--xs;
            }

            @include media-breakpoint-down(md) {
                padding: $space--md;
            }

            @include media-breakpoint-down(sm) {
                display: block;
                margin-top: $space--md;
            }

            &--top {
                background-color: rgba($primary, 0.8);
                margin-bottom: $space--xs;
                padding: $space--xxs;
            }
        }

        &_title {
            @include DesktopH1;

            @include media-breakpoint-down(md) {
                @include DesktopH3;
            }

            &_event {
                max-width: 660px;
            }
        }

        &_text {
            background: rgba($bg--primary, 0.8);
            padding: $space--xxs;

            @include BodyCopyLg;

            @include media-breakpoint-down(md) {
                @include BodyCopySm;
            }

            &-location {
                max-width: fit-content;
            }
        }

        &_breadcrumb {
            margin-bottom: $space--lg;
            font-size: $font-size--sm;
            display: flex;

            &-link {
                &::after {
                    content: '/';
                    margin: $space--tiny;
                }

                &:last-child {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }

    &__search {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        column-gap: $space--xs;
        padding: $space--lg;
        background: $bg--primary;
        margin-top: 0;
        max-width: unset;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__side {
        &-links {
            position: fixed;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            z-index: 100;

            @include transition;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &-link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            cursor: pointer;

            @include transition;

            &--primary {
                background: $bg--secondary;
            }

            &--secondary {
                background: $bg--primary;
            }

            &:hover {
                color: $text--light;
                padding: $space--xl 0 $space--md 0;

                .hero__side-label {
                    opacity: 1;
                    visibility: visible;
                    position: relative;
                    left: unset;
                    padding-right: 0;
                }

                .hero__side-icon {
                    position: absolute;
                    top: 0;
                }
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            color: $bg--white;
        }

        &-label {
            position: absolute;
            left: 0;
            padding-right: $space--xs;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            color: $text--light;
            transform: rotate(180deg);
            text-transform: uppercase;
            writing-mode: vertical-rl;
            text-orientation: revert;
            font-size: $font-size--xs;
            font-weight: 400;
        }
    }

    &__event-banner {
        display: flex;
        height: 200px;

        @include media-breakpoint-down(md) {
            display: block;
            height: unset;
        }

        &-md {
            display: flex;
            height: 150px;

            @include media-breakpoint-down(md) {
                display: block;
                height: unset;
            }
        }

        &-sm {
            display: flex;
            height: 100px;

            @include media-breakpoint-down(md) {
                display: block;
                height: unset;
            }
        }

        &-wrapper {
            background-color: rgba($bg--primary, 0.8);
            color: $text--light;
            padding: $space--xs;
            display: block;
        }

        &--details {
            display: flex;
            min-width: 650px;
            flex-wrap: wrap;

            @include media-breakpoint-down(md) {
                display: block;
                min-width: unset;
            }
        }

        &--logo {
            display: contents;

            @include media-breakpoint-down(md) {
                display: block;
                height: 150px;
                width: 150px;
            }

            &_image {
                width: 100%;
                height: 100%;
                align-self: center;
                background-color: $bg--white;
                object-fit: cover;

                @include media-breakpoint-down(md) {
                    min-height: unset;
                }
            }
        }

        &--location,
        &--stand-location,
        &--date {
            padding: 0 $space--xs;

            &:first-child {
                border-right: 1px solid $border--white;

                @include media-breakpoint-down(md) {
                    border-right: 0px;
                }
            }

            &_text {
                @include BodyCopySm;
            }

            &_content {
                @include BodyCopyLg;
            }

            @include media-breakpoint-down(md) {
                border-bottom: 1px solid $white;
                border-right: none;
                padding: $space--xxxs 0;
                margin: 0 $space--xs;
            }
        }

        &--location,
        &--stand-location {
            flex: 0 0 50%;
        }

        &--date {
            flex: 0 0 50%;
        }

        &--countdown {
            padding: $space--xxxs 0 0 $space--xs;
            display: flex;

            @include BodyCopyLg;

            @include media-breakpoint-down(md) {
                @include BodyCopySm;
            }
        }
    }

    &__modal {
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        opacity: 0;
        visibility: hidden;

        @include transition;

        &-body {
            display: flex;
            width: 35%;
            margin: $space--xl auto;
            padding: 50px;
            column-gap: 90px;
            background-color: $bg--white;
            z-index: 500;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            @include media-breakpoint-down(sm) {
                column-gap: 0;
            }
        }

        &-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($bg--black, 0.8);
            z-index: -1;
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size--xl;
            color: $text--dark;
            cursor: pointer;
            z-index: 101;
        }

        &-content {
            position: relative;
            overflow-y: auto;
            max-height: 600px;

            &--title {
                padding-bottom: $space--xs;
                padding-top: $space--xl;
                @include DesktopH3;
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 600px;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &-input {
            border: 1px solid $border--dark;
            width: 280px;
            height: 40px;
            padding: $space--xxs;
        }

        &-details {
            display: grid;
            padding-bottom: $space--xs;
        }

        &-consent {
            margin-bottom: $space--sm;
            @include BodyCopySm;
        }
    }
}

.hero__event-page + .hero__carousel_slide {
    @include media-breakpoint-down(md) {
        height: calc(100vh - 50px);
    }

    @include media-breakpoint-down(sm) {
        height: 100%;
    }
}

#form-DownloadBrochure {
    .rte {
        padding: 0;
    }

    form {
        margin-right: 40px;
        margin-top: 20px;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }

    .form-title {
        margin-top: 42px;
    }
}

/*=====  End of Hero  ======*/
