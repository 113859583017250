/*=============================================
=            Pagination            =
=============================================*/

.pagination {
	display: flex;
    text-align: center;
    padding: $space--lg;
    justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        padding: $space--lg 0;
    }

    &__page {
        color: rgba($text--dark, .3);
        border: 1px solid rgba($black, .3);
        border-radius: 3px;
        width: 30px;
        height: 30px;
        padding: $space--tiny 0;
        margin: 0 $space--tiny;
        cursor: pointer;
        margin-bottom: $space--xxxs;

        &:hover {
            color: $text--dark;
            border: 1px solid $bg--primary;
        }

        &--active {
            color: $text--light;
            background-color: $bg--primary;
            border: none;

            &:hover {
                color: $text--light;
                background-color: $bg--primary;
            }
        }
    }

    &__hidden {
        display: none;
    }

    .button--icon-only {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 30px;
        height: 30px;
    }
}

/*=====  End of Pagination  ======*/
