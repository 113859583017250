/*=============================================
=            Title Banner            =
=============================================*/

.title-banner {
    position: relative;
    color: $text--light;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;

    &__wrapper {
        padding: $space--xxl;
        position: relative;
    }

    &__overlay {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba($bg--primary--dark, .9);
        }
    }
	
    &__title {
        @include DesktopH1;
        padding-bottom: $space--md;
    }

    &__description {
        @include BodyCopyLg;
        max-width: 600px;
    }
}

/*=====  End of Title Banner  ======*/
