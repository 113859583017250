/*=============================================
=            Case Study Stats            =
=============================================*/

.case-study-stats {
    background-color: $bg--primary;
    color: $text--light;
    padding: $space--md $space--xxl;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(md) {
        display: block;
    }

    &__description {
        padding-right: $space--xxl;
        @include BodyCopyLg;

        @include media-breakpoint-down(md) {
            padding-right: 0;
            padding-top: $space--xxs;
        }
    }

    &__left,
    &__right {
        @include media-breakpoint-down(md) {
            flex: 100%;
        }
    }

    &__left {
        flex: 0 0 30%;
    }

    &__right {
        flex: 0 0 70%;
    }

    &__left {
        @include media-breakpoint-down(md) {
            order: 2;
        }
    }

    &__right {
        @include media-breakpoint-down(md) {
            order: 1;
        }
    }

    &__stats {
        display: flex;
        justify-content: flex-start;
        @include BodyCopyLg;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(sm) {
            border-top: 1px solid rgba($border--white, 0.4);
            padding-top: 5px;
        }
    }

    &__stat {
        margin-bottom: $space--tiny;
        border-right: 1px solid rgba($border--white, 0.4);
        padding: 0 $space--xs;

        @include media-breakpoint-down(sm) {
            border-right: none;
        }

        &:nth-child(2) {
            padding: 0 $space--xs;
            flex: 0 0 45%;

            @include media-breakpoint-down(xl) {
                flex: unset;
            }

            @include media-breakpoint-down(md) {
                border-right: none;
                flex: 40%;
            }

            @include media-breakpoint-down(sm) {
                padding: 0;
                flex: 100%;
            }
        }

        &:nth-child(3) {
            border-right: none;
        }

        &:last-child {
            flex: 0 0 45%;

            @include media-breakpoint-down(md) {
                flex: 100%;
                padding: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            flex: 52%;
            padding: 0;

            &:nth-child(2) {
                padding-left: 0;
                border: none;
            }
        }

        &-label {
            @include BodyCopySm;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &:last-child {
            border-right: none;

            @include media-breakpoint-down(sm) {
                padding: $space--tiny 0;
                border-top: 1px solid rgba($border--white, 0.4);
                border-bottom: 1px solid rgba($border--white, 0.4);
            }
        }
    }

    &__applications {
        margin-top: $space--xs;

        &-title {
            margin-bottom: $space--tiny;

            @include BodyCopyXs;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            row-gap: $space--xs;
        }

        &-item {
            margin-right: $space--xxxs;
            border: 1px solid $bg--white;
            border-radius: 28px;
            padding: 0 $space--xxxs;
            height: 28px;

            @include BodyCopySm;
            line-height: 28px;
        }
    }
}

/*=====  End of Case Study Stats  ======*/
