/*=============================================
=			Pop-Up			=
=============================================*/

.pop-up {
    &__modal {
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;

        @include transition;

        &-body {
            display: flex;
            width: 30%;
            margin: 150px auto;
            padding: 50px;
            column-gap: 90px;
            background-color: $bg--white;
            z-index: 500;
            justify-content: center;
            position: relative;

            @include media-breakpoint-down(lg) {
                width: 50%;
            }

            @include media-breakpoint-down(sm) {
                column-gap: 0;
                width: 100%;
            }
        }

        &-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($bg--black, 0.8);
            z-index: -1;
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size--xl;
            color: $text--light;
            cursor: pointer;
            z-index: 101;
        }

        h3 {
            font-size: 35px;
            margin: 20px 0 10px 0;
            text-align: center;
        }

        &-content {
            position: relative;
            // overflow-y: auto;
            max-height: 70vh;
            color: $text--light;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow-y: auto;
            overflow-x: hidden;
        }

        img {
            max-width: 300px;
            margin: 0 auto;
        }

        &-text {
            font-size: $font-size--sm;
            margin: 20px 0;
            line-height: $line-height--lg;
            text-align: center;
        }

        &-link {
            border: 1px solid $white;
            padding: 15px;
            font-weight: 500;
        }
    }
}

.body--hidden {
    overflow: hidden;
}

/*=====  End of Pop-Up  ======*/
