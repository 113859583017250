$font-family--base: Gotham;

// font sizes
$font-size--mega: 60px;
$font-size--xxxl: 50px;
$font-size--xxl: 40px;
$font-size--xl: 30px;
$font-size--quote: 25px;
$font-size--lg: 20px;
$font-size--md: 16px;
$font-size--sm: 15px;
$font-size--xs: 14px;
$font-size--xxs: 13px;

// font line heights
$line-height--mega: 60px;
$line-height--xxxl: 50px;
$line-height--xxl: 40px;
$line-height--xl: 30px;
$line-height--lg: 20px;
$line-height--md: 16px;
$line-height--sm: 15px;
$line-height--xs: 14px;
$line-height--xxs: 13px;

// font weights
$font-weight--black: 700;
$font-weight--bold: 400;
$font-weight--normal: 300;

// desktop

@mixin DesktopH1Mega {
    font-size: $font-size--mega;
    font-weight: $font-weight--black;
    line-height: 100%;
    text-transform: uppercase;
}

@mixin DesktopH1 {
    font-size: $font-size--xxxl;
    font-weight: $font-weight--black;
    line-height: 100%;
    text-transform: uppercase;
}

@mixin DesktopH2 {
    font-size: $font-size--xxl;
    font-weight: $font-weight--black;
    line-height: 100%;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
        font-size: $font-size--xl;
    }
}

@mixin DesktopH3 {
    font-size: $font-size--xl;
    font-weight: $font-weight--black;
    line-height: 100%;
    text-transform: uppercase;
}

@mixin DesktopH3Sm {
    font-size: $font-size--lg;
    font-weight: $font-weight--black;
}

@mixin DesktopH4 {
    font-size: $font-size--md;
    font-weight: $font-weight--bold;
    line-height: 100%;
    text-transform: uppercase;
}

@mixin DesktopH5 {
    font-size: $font-size--sm;
    font-weight: $font-weight--bold;
    line-height: 100%;
    text-transform: uppercase;
}

@mixin BodyCopyLg {
    font-size: $font-size--lg;
    line-height: $line-height--xl;
}

@mixin BodyCopy {
    font-size: $font-size--md;
    line-height: $line-height--lg;
}

@mixin BodyCopySm {
    font-size: $font-size--xs;
    line-height: $line-height--lg;
}

@mixin BodyCopyXs {
    font-size: $font-size--xxs;
    line-height: $line-height--lg;
}

@mixin FormLabel {
    font-size: $font-size--sm;
    line-height: $line-height--sm;
}
