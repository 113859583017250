/*=============================================
=            Accordion            =
=============================================*/

.accordion {
    display: flex;

    @include media-breakpoint-down(md) {
        display: block;
    }

	&__sidebar {
        position: relative;
        padding: $space--xxl;
        background-color: $bg--primary;
        color: $text--light;
        width: 320px;

        @include media-breakpoint-down(md) {
            display: flex;
            padding: $space--md;
            width: auto;
        }

        &_link {
            margin-bottom: $space--xxs;
            font-size: $font-size--xs;
            white-space: nowrap;
            cursor: pointer;

            @include transition;

            .zmdi {
                opacity: 0;
                margin-right: $space--xxxs;

                @include transition;
            }

            &--active {
                padding-left: $space--sm;

                .zmdi {
                    opacity: 1;
                }
            }

            @include media-breakpoint-down(md) {
                margin-right: $space--lg;
            }
        }

        &_links {
            position: absolute;

            &--fixed {
                position: relative;
                top: $space--md;
            }
        }
    }

    &__panes {
        position: relative;
        flex: 1;
        overflow: hidden;

        @include transition;
    }

    &__pane {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        padding: 0 $space--xxl;

        @include transition;

        &--active {
            opacity: 1;
            visibility: visible;
        }
    }
}

/*=====  End of Accordion  ======*/
