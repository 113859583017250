/*=============================================
=            Media Gallery            =
=============================================*/

@import 'swiper/swiper';

.media-gallery {
    padding: $space--xxl;

    @include media-breakpoint-down (md) {
        padding: $space--md 0 $space--md $space--md;
    }

    &__heading {
        margin: 0 $space--md $space--md 0;
    }

    &__filters {
        display: flex;
        margin-bottom: $space--md;
    }

    &__filter {
        text-decoration: none;
        margin-right: $space--xxxs;
        border: 1px solid $bg--primary;
        border-radius: 20px;
        padding: $space--tiny $space--xxxs;
        font-size: $font-size--sm;
        font-weight: $font-weight--bold;
        text-transform: uppercase;
        color: $text--primary;
        white-space: nowrap;
        cursor: pointer;

        @include transition;

        &:hover {
            background-color: $bg--primary;
            color: $text--light;
        }

        &.active {
            background-color: $bg--black;
            border: 1px solid $bg--black;
            color: $text--light;

            &:hover {
                background-color: $bg--black;
                border: 1px solid $bg--black;
                color: $text--light;
            }
        }
    }

    &__item {
        display: block;

        &--text-wrapper {
            display: flex;
            margin-top: $space--xxxs;
        }

        &--title {
            padding-right: $space--tiny;
            color: $grey;

            @include BodyCopyXs;
        }

        &--description {
            color: $text--dark;

            @include BodyCopyXs;
        }
    }

    .swiper {
        cursor: pointer;
    }

    &__scrollbar {
        margin-top: $space--md;
        background: rgba($bg--black, .1);
        height: 10px;
        width: 100%;

        @include media-breakpoint-down (desktop) {
            max-width: calc(100% - $space--md);
        }

        .swiper-scrollbar-drag {
            background: $bg--primary;
            height: inherit;
        }
    }

    &__modal {
        &-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            opacity: 0;
            visibility: hidden;

            @include transition;

            &--show {
                opacity: 1;
                visibility: visible;
            }
        }

        &-body {
            img,
            video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 500;
                max-width: 85%;
                max-height: 85%;
            }
        }

        &-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($bg--black, .8);
            z-index: 100;
        }

        &-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size--lg;
            color: $text--light;
            cursor: pointer;
            z-index: 101;
        }
    }
}

.hide {
    display: none;
}

.show {
    display: block;
}

/*=====  End of Media Gallery  ======*/
