/*=============================================
=            Header            =
=============================================*/

.header {
    padding-bottom: 90px;

    form {
        max-width: unset;
        width: unset;
        margin: unset;
        margin-top: unset;
    }

    @include media-breakpoint-down(md) {
        margin-top: unset;
    }

    @include media-breakpoint-down(lg) {
        padding-bottom: 65px;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: $bg--primary;
        color: $text--light;
        font-size: $font-size--xs;
        padding: 0 $space--md;
        height: 40px;

        @include media-breakpoint-down(lg) {
            background: $bg--black;
            justify-content: flex-start;
            padding: 0 $space--xxs;
        }

        &-item {
            @include media-breakpoint-up(lg) {
                margin-left: $space--xs;
            }

            &--divider {
                width: 1px;
                height: 21px;
                background: rgba($bg--white, 0.2);

                @include media-breakpoint-down(md) {
                    margin: 0 20px;
                }

                @include media-breakpoint-down(xs) {
                    margin: 0 7px;
                }
            }

            @include media-breakpoint-down(lg) {
                &--hide {
                    display: none;
                }
            }

            &-dropdown {
                cursor: pointer;
                font-family: $font-family--base;
                font-weight: 300;

                @include BodyCopySm;

                & + & {
                    margin-left: $space--xs;
                }
            }
        }

        &-items {
            display: flex;
        }

        option {
            color: black;
        }
    }

    &__main {
        position: absolute;
        display: flex;
        justify-content: space-between;
        height: 90px;
        padding: 0 $space--lg 0 $space--md;
        background: $bg--white;
        z-index: 100;
        width: 100%;

        @include media-breakpoint-down(lg) {
            align-items: center;
            height: 65px;
            padding: 0 $space--xxs;
        }

        &--fixed {
            position: fixed;
            top: 0;
            left: 0;
            max-width: 100vw;

            .header__mobile-wrapper {
                top: 65px;
                height: calc(100% - 65px);
            }
        }

        &-wrapper {
            display: none;
            align-items: center;

            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }

        &-nav {
            @include media-breakpoint-up(lg) {
                display: flex;
                margin: 0;
                padding: 0;
            }
        }

        &-trigger {
            display: inline-block;
            position: relative;
            width: 19px;
            height: 18px;
            cursor: pointer;

            @include media-breakpoint-up(lg) {
                display: none;
            }

            div {
                position: absolute;
                left: 50%;
                width: 100%;
                height: 2px;
                border-radius: 2px;
                background: $bg--black;

                @include transition;

                &:nth-child(1) {
                    top: 0;
                    transform: translateX(-50%);
                }

                &:nth-child(2) {
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &:nth-child(3) {
                    top: 100%;
                    transform: translate(-50%, -100%);
                }
            }

            &--active {
                div {
                    &:nth-child(1),
                    &:nth-child(3) {
                        top: 50%;
                        background: $bg--primary;
                        width: 150%;
                    }

                    &:nth-child(1) {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }

                    &:nth-child(2) {
                        width: 0;
                    }
                }
            }
        }

        &-tel {
            @include media-breakpoint-up(lg) {
                display: none;
            }

            svg {
                width: 28px;
            }
        }

        &-logo {
            display: flex;
            align-items: center;

            .logo {
                max-width: 245px;

                @include media-breakpoint-down(md) {
                    max-width: 190px;
                }
            }
        }

        &-search {
            position: relative;
            display: flex;
            align-items: center;
            opacity: 0;
            visibility: hidden;
            margin-right: -305px;

            @include transition;

            form {
                margin-top: unset;
                @include media-breakpoint-down(md) {
                    margin-top: unset;
                }
            }

            &--active {
                opacity: 1;
                visibility: visible;
                margin-right: $space--xxs;
            }

            &-field {
                height: 41px;
                line-height: 41px;
                border-radius: 41px;
                border: 1px solid rgba($border--dark, 0.3);
                width: 305px;
                padding: 0 $space--xs;
            }

            &-submit {
                position: absolute;
                top: 50%;
                right: 1px;
                padding: 0 $space--xs;
                transform: translateY(-50%);
                font-size: $font-size--lg;
                cursor: pointer;
                background: $bg--white;
                border-radius: 41px;
            }

            &-trigger {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 30px;
                height: 30px;
                font-size: $font-size--lg;
                cursor: pointer;
                border-radius: 50%;

                @include transition;

                .zmdi {
                    position: absolute;
                    opacity: 0;

                    @include transition;
                }
                &:focus {
                    outline: 1px solid $bg--black;
                }
                .icon {
                    opacity: 1;
                }

                &--active {
                    background: $bg--primary;
                    color: $text--light;

                    .icon {
                        opacity: 0;

                        &--active {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &-menu {
            display: flex;
            justify-content: flex-end;

            &_li {
                display: inline-flex;
                align-items: center;
                margin-left: $space--xs;
                height: 100px;

                &:hover,
                &:focus {
                    .header__main-menu__sub {
                        opacity: 1;
                        visibility: visible;
                        @include transition(all, 0s, ease, 0s);
                    }
                }
            }

            &_a {
                display: flex;
                align-items: center;
            }

            &__sub {
                position: absolute;
                top: 90px;
                right: 0;
                display: flex;
                box-shadow: -15px 15px 25px 5px rgba(0, 0, 0, 0.2);
                opacity: 0;
                visibility: hidden;

                @include transition(all, 0s, ease, 0.5s);

                @include media-breakpoint-down(lg) {
                    top: 65px;
                }

                @include media-breakpoint-down(xxxl) {
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }

                &_sub--active {
                    display: block;
                }

                &-li:hover,
                &-li:focus,
                &-li:focus-within {
                    .header__main-menu__sub_sub {
                        display: block;
                    }

                    & > .header__main-menu__sub-a {
                        background: $bg--white;
                        color: $text--primary;
                    }
                }

                &--active {
                    &.header__main-menu__sub {
                        opacity: 1;
                        visibility: visible;

                        @include transition(all, 0.4s, ease);
                    }
                }

                &--alpha {
                    .header__main-menu__sub {
                        &-a {
                            display: block;
                            line-height: 70px;
                            background: $bg--primary;
                            color: $text--light;
                            min-width: 225px;
                            text-align: center;
                        }

                        &-li {
                            min-width: 100px;
                        }
                    }
                }

                &--beta {
                    background: $bg--white;

                    @include BodyCopySm;

                    .header__main-menu__sub {
                        &-links {
                            position: relative;
                            padding: $space--md;
                            width: 520px;

                            &--sub {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                background: $bg--white--alt;
                                height: 100%;
                                display: none;

                                @include transition(all, 0.4s, ease);

                                &.header__main-menu__sub-sub--active {
                                    display: block;
                                    width: 290px;
                                }
                            }
                        }

                        &-ul {
                            margin-top: $space--xxxs;
                            padding-top: $space--xxxs;
                            border-top: 1px solid rgba($bg--black, 0.1);
                            max-width: calc(50%);
                        }

                        &-li {
                            margin-bottom: $space--xxxs;

                            &:hover,
                            &:focus {
                                .header__main-menu__sub-links--sub {
                                    display: block;
                                    width: 290px;
                                }
                            }
                        }

                        &-a {
                            display: block;
                            color: $text--primary;

                            &:hover {
                                background: transparent;
                                color: $text--dark;
                            }
                        }
                    }
                }

                &-image {
                    position: relative;
                    width: 380px;
                    min-height: 380px;
                    height: inherit;
                    flex: 1;

                    &-img {
                        position: absolute;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;

                        @include transition;
                    }

                    &-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: flex-end;
                        background: rgba($bg--black, 0.5);
                        opacity: 0;
                        visibility: hidden;
                        z-index: 1;
                        padding: $space--md;
                        color: $text--light;
                        display: none;

                        @include transition;
                    }

                    &:hover .header__main-menu__sub-image-overlay {
                        opacity: 1;
                        visibility: visible;
                    }

                    &:focus .header__main-menu__sub-image-overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &_sub {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    background: $bg--white;
                    display: none;

                    @include transition(all, 0.4s, ease);

                    &-ul {
                        padding: $space--md;

                        &.inline {
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 1px solid $white-alt;
                        }
                    }

                    &-li {
                        padding: $space--tiny;
                    }

                    &-a {
                        display: block;
                        padding: $space--tiny;
                        color: $text--primary;
                    }
                }
            }
        }
    }

    &__mobile {
        &-wrapper {
            position: fixed;
            top: 105px;
            left: 0;
            width: 100%;
            height: calc(100% - 105px);
            padding: $space--xs $space--md;
            background: $bg--white;
            z-index: 10001;
            transform: translateX(100%);

            @include transition;

            &--active {
                transform: translateX(0);
            }
        }

        &-divider {
            width: 100%;
            border-top: 1px solid rgba($bg--black, 0.1);
            margin: $space--xs 0;
        }

        &-li {
            display: flex;
            align-items: center;
            margin-bottom: $space--sm;

            &:last-child {
                margin-bottom: $space--lg;
            }
        }

        &-a {
            display: flex;
            justify-content: space-between;
            flex: 1;
            text-transform: uppercase;
            color: $text--primary;
            font-weight: $font-weight--bold;
            cursor: pointer;

            @include transition;

            &:hover {
                color: $text--dark;
            }
        }

        &-subheading {
            display: flex;
            justify-content: space-between;
            flex: 1;
            text-transform: uppercase;
            color: $text--dark;
            font-weight: $font-weight--bold;

            @include transition;
        }

        &-tel {
            font-size: $font-size--xs;

            a {
                display: inline-block;
                color: $text--primary;
                font-weight: $font-weight--bold;
                margin-left: $space--xxs;
            }
        }

        &-cta {
            text-transform: uppercase;
            color: $text--primary;
            font-weight: $font-weight--bold;
        }

        &-search {
            margin-right: 0;
            opacity: 1;
            visibility: visible;

            .header__main-search-field {
                width: 100%;
            }

            .header__main-search-submit {
                font-size: 24px;
            }
        }

        &-sub-ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $bg--white;
            padding: $space--xs $space--md;
            z-index: 2;
            transform: translateX(100%);

            @include transition;

            &--active {
                transform: translateX(0);
                overflow: hidden;

                .header__mobile-sub-ul--active {
                    overflow-y: scroll;
                    overflow-x: hidden;
                }
            }

            .header__mobile {
                &-li {
                    justify-content: space-between;
                }

                &-a {
                    &--inline {
                        flex: none;
                    }

                    .zmdi-chevron-left {
                        margin-right: $space--xs;
                    }
                }

                &-divider {
                    margin: $space--sm 0;
                }

                &-title {
                    font-size: $font-size--xxs;
                    color: rgba($bg--black, 0.2);
                }

                &-subheading {
                    color: $text--dark;
                    font-weight: $font-weight--bold;
                }
            }
        }
    }
}

.header__main-menu__sub_sub--active {
    display: block;
}

.header__main-menu_li-toggle {
    left:-999px;
    position:absolute;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
}

.header__main-menu_li-toggle:focus{
    position: relative;
    left: unset;
    top: unset;
    width: unset;
    height: unset;
    z-index: 999;
    margin-left: 10px;
    font-size: 22px;
}

/*=====  End of Header  ======*/
